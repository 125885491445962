import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { useCheckEmailLazyQuery, useCheckUsernameLazyQuery, useRegisterMutation } from 'generated/graphql';
import LayoutSmall from 'layouts/LayoutSmall';
import { observer } from 'mobx-react-lite';
import { Store } from 'model/store/Store';
import { INDEX, LOGIN } from 'routes';
import { emailRegex, pwdRegex, usernameRegex } from 'shared/regex';

interface IFormState {
  username: string,
  email: string,
  password: string,
  passwordConfirm: string,
}

const RegisterPage: FC = () => {

  const store = useInstance(Store);
  const navigate = useNavigate();
  const { state } = useLocation();

  const { t } = useTranslation();

  const [registerMutation, {loading}] = useRegisterMutation();
  const [checkEmail] = useCheckEmailLazyQuery();
  const [checkUsername] = useCheckUsernameLazyQuery();

  const onSubmit = async (values: IFormState) => {
    await registerMutation({
      variables: {
        input: {
          username: values.username,
          email: values.email,
          password: values.password
        }
      },
      onCompleted: (data: any) => {
        const jwt = data?.register.jwt;
        const userId = data?.register.user.id;
        store.auth.setTokenAndId(jwt, userId);
        store.domain.clearState();
        navigate((state && state.backTo) || INDEX);
      },
      onError: (error) => {
        message.error(error.message);
      }
    });
  };

  const [form] = Form.useForm<IFormState>();

  const initialFormState: IFormState = useMemo(() => ({
    username: '',
    email: (state && state.email) || '',
    password: '',
    passwordConfirm: ''
  }), [state]);

  const emailValidate = async (rule: any, value: any) => {
    const queryResult = await checkEmail({
      variables: { email: value }
    });
    const valid = queryResult.data?.checkEmail?.valid;
    if (valid) {
      return Promise.resolve();
    } else {
      return Promise.reject(t('Email taken'));
    }
  };

  const usernameValidate = async (rule: any, value: any) => {
    const queryResult = await checkUsername({
      variables: { username: value }
    });
    const valid = queryResult.data?.checkUsername?.valid;
    if (valid) {
      return Promise.resolve();
    } else {
      return Promise.reject(t('Username taken'));
    }
  };

  return (
    <LayoutSmall title={t('Registration')}>

      <Form
            layout={'vertical'}
            initialValues={initialFormState}
            form={form}
            onFinish={onSubmit}
            scrollToFirstError
      >

        <Form.Item name="username" label={t('Username')}
                   hasFeedback
                   validateDebounce={1000}
                   validateFirst={true}
                   rules={[
                     { required: true, message: t('Required')! },
                       { min: 4, message: `${t('Min 4 symbols')}` },
                       { pattern: usernameRegex, message: t('Only latin letters and digits')! },
                     { validator: usernameValidate }
                   ]}

        >
          <Input />
        </Form.Item>

        <Form.Item name="email" label={t('Email')}
                   hasFeedback
                   validateFirst={true}
                   validateDebounce={1000}
                   rules={[
                     { required: true, message: `${t('Required')}` },
                     { pattern: emailRegex, message: `${t('Invalid format')}` },
                     { validator: emailValidate }
                   ]}
        >
          <Input placeholder="example@site.com" autoComplete={'none'} />
        </Form.Item>

        <Form.Item name="password" label={t('Password')}
                   hasFeedback
                   validateFirst={true}
                   rules={[
                     { required: true, message: `${t('Required')}` },
                       { min: 6, message: `${t('Min 6 symbols')}` },
                       { pattern: pwdRegex, message: t('Only latin letters and digits')! },
                   ]}>
          <Input.Password autoComplete={'none'} />
        </Form.Item>

        <Form.Item name="confirmPassword" label={t('Confirm password')}
                   hasFeedback
                   dependencies={['password']}
                   validateFirst={true}
                   rules={[
                     { required: true, message: `${t('Required')}` },
                     { min: 6, message: `${t('Min 6 symbols')}` },
                     { pattern: pwdRegex, message: t('Only latin letters and digits')! },
                     ({ getFieldValue }) => ({
                       validator(_, value) {
                         if (!value || getFieldValue('password') === value) {
                           return Promise.resolve();
                         }
                         return Promise.reject(new Error(t('Passwords do not match')!));
                       }
                     })
                   ]}>
          <Input.Password />
        </Form.Item>

        {/*<Form.Item>*/}
        {/*  <Checkbox>{'Согласен с Пользовательским соглашением и Политикой обработки персональных данных'}</Checkbox>*/}
        {/*</Form.Item>*/}

        <Form.Item>
          <Button block type="primary" htmlType="submit" loading={loading}>{t('Next')}</Button>
        </Form.Item>

        <Button block type={'link'} onClick={() => navigate(LOGIN)}>{t('Login')}</Button>

      </Form>

    </LayoutSmall>
  );
};

export default observer(RegisterPage);



