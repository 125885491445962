import { useTranslation } from 'react-i18next';
import { MailOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import LayoutSmall from 'layouts/LayoutSmall';

import cls from './Feedback.module.scss';

export const Feedback = () => {
    const { t } = useTranslation();

    return (
        <LayoutSmall title={t('Feedback')}>
            <div className={cls.contacts}>
                {/*<span>{t('Contact us in any way:')}</span>*/}
                <span>{t('Email us:')}</span>
                <Button icon={<MailOutlined/>} block={true} onClick={() => window.open('mailto:app@saturn.run')}>{'app@saturn.run'}</Button>
            </div>
        </LayoutSmall>
    );
};
